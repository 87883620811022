import React from "react"
import Layout from "../components/layout"

const A = () => (
  <Layout>
    <p>A</p>
  </Layout>
)

export default A
